import { Dropdown, Navbar } from 'flowbite-react'
import { useEffect } from 'react'
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { auth } from '../firebase'
import { useCheckAdmin } from '../base/useDbHooks'

function MenuDropdown({
  label,
  items,
}: {
  label: string
  items: { label: string; onClick: () => void }[]
}) {
  return (
    <Dropdown label={label} inline className='text-lg px-6 py-4 font-bold'>
      {items.map((item, idx) => (
        <Dropdown.Item
          key={idx + item.label}
          onClick={item.onClick}
          className='text-lg py-2 px-6 hover:bg-gray-200 font-bold'
        >
          {item.label}
        </Dropdown.Item>
      ))}
    </Dropdown>
  )
}

export default function Header() {
  const [user, loading] = useAuthState(auth)
  const [signOut] = useSignOut(auth)
  const { pathname } = useLocation()
  const { isAdmin } = useCheckAdmin()
  const navigate = useNavigate()

  useEffect(() => {
    if (!loading) {
      if (!user) {
        navigate('/signin')
      } else if (!isAdmin) {
        navigate('/')
      }
    }
  }, [user, isAdmin, pathname, loading, navigate])

  if (loading) return <div className='spinner'>Loading...</div>
  if (!isAdmin) return null

  const menuItems = {
    products: [
      { label: 'Products', onClick: () => navigate('/products') },
      { label: 'Stock', onClick: () => navigate('/stock') },
      { label: 'Pricing', onClick: () => navigate('/pricing') },
      { label: 'Stock and Price', onClick: () => navigate('/stockandpricing') },
    ],
    reports: [
      { label: 'WhatsApp Messages', onClick: () => navigate('/reports/whatsappmsg') },
      { label: 'Daily Sales', onClick: () => navigate('/reports/salesReports') },
      { label: 'Monthly Sales', onClick: () => navigate('/reports/salesReportsMonthly') },
      {
        label: 'Monthly Sales Product',
        onClick: () => navigate('/reports/salesReportsMonthlyProduct'),
      },
      { label: 'Invoice List', onClick: () => navigate('/reports/salesReportsInvoice') },
      { label: 'Last 10 Orders', onClick: () => navigate('/reports/LastInvoicesList') },
      { label: 'Last 10 Online Orders', onClick: () => navigate('/reports/LastOnlineInvoicesList') },
    ],
    settings: [
      { label: 'Categories', onClick: () => navigate('settings/categories') },
      { label: 'Delivery Timeslots', onClick: () => navigate('settings/deliveryTimeslot') },
      { label: 'Pickup Timeslots', onClick: () => navigate('settings/pickupTimeslot') },
      { label: 'Landmark', onClick: () => navigate('settings/landmark') },
      { label: 'Delivery Charges', onClick: () => navigate('settings/deliveryCharges') },
      { label: 'Delivery Locations', onClick: () => navigate('settings/deliveryLocations') },
      { label: 'Other Settings', onClick: () => navigate('settings/otherSettings') },
    ],
    avatar: [
      { label: 'Dashboard', onClick: () => navigate('/dashboard') },
      { label: 'Settings', onClick: () => navigate('/settings') },
      { label: 'Earnings', onClick: () => navigate('/earnings') },
      {
        label: 'Sign Out',
        onClick: async () => {
          try {
            await signOut()
          } catch (error) {
            console.error('Error signing out:', error)
          }
        },
      },
    ],
  }

  return (
    <Navbar fluid rounded className='text-lg px-6 py-4 font-bold'>
      <Navbar.Brand onClick={() => navigate('/')}>
        <div className='flex flex-col'>
          <span className='self-center whitespace-nowrap text-2xl font-semibold dark:text-white'>
            Royal Bakes
          </span>
          <span className='self-center whitespace-nowrap text-xl dark:text-white'>Admin</span>
        </div>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Navbar.Link onClick={() => navigate('/')}>Home</Navbar.Link>
        <MenuDropdown label='Products' items={menuItems.products} />
        <Navbar.Link onClick={() => navigate('/orders')}>Orders</Navbar.Link>
        <Navbar.Link onClick={() => navigate('/users')}>Users</Navbar.Link>
        <MenuDropdown label='Reports' items={menuItems.reports} />
        <MenuDropdown label='Settings' items={menuItems.settings} />
        <Navbar.Link>
          <MenuDropdown
            label={user?.displayName || user?.email || 'User'}
            items={menuItems.avatar}
          />
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  )
}
