import { Page } from '../../base/Page'
import { ProductOrder } from '../../model/Order'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { Button, Modal } from 'flowbite-react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  calculateBalance,
  useAddOrderItem,
  useOrder,
  useOrderItems,
  useRemoveOrderItem,
  useUpdateOrderItem,
  useUpdateOrderTotals,
  useUpdatePaidAmount,
} from '../../base/useDbOrders'
import {
  useGetAllPricing,
  useOtherSettings,
  useProducts,
  useUpdateUserBalance,
} from '../../base/useDbHooks'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { format } from 'date-fns'
import debounce from 'lodash/debounce'

export default function OrderView() {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { order, loading: orderLoading } = useOrder(id);
  const { otherSettings } = useOtherSettings();
  useUpdateOrderTotals(id);

  console.log('OrderView - order:', order);

  const formattedDate = useMemo(() =>
    order?.orderDate ? format(new Date(Number(order.orderDate)), 'PPp') : '',
    [order?.orderDate]
  );

  const formattedTotal = useMemo(() => {
    const total = order?.totalAmount ?? 0;
    console.log('OrderView - formatting total:', total);
    return new Intl.NumberFormat('ja-JP', { maximumFractionDigits: 0 }).format(total);
  }, [order?.totalAmount]);

  if (orderLoading) return <Page title="Order View"><div className="p-4">Loading...</div></Page>;
  if (!order) return <Page title="Order View"><div className="p-4">Order not found</div></Page>;

  return (
    <Page title={`Order #${id}`}>
      <div className="container mx-auto p-4 space-y-6 max-w-4xl">
        <div className="bg-gray-100 p-4 rounded-lg shadow-sm">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <div className="flex flex-col gap-2">
              <h2 className="text-xl font-semibold">Order #{id}</h2>
              <span className="text-gray-600">{formattedDate}</span>
            </div>
            <div className="flex flex-col gap-2">
              <span className={`text-lg font-bold ${getStatusColor(order.orderStatus)}`}>
                {order.orderStatus}
              </span>
              <span className="text-2xl font-bold text-gray-800">¥{formattedTotal}</span>
            </div>
          </div>
          <div className="mt-4 text-gray-700">
            <span className="font-semibold">{order.deliveryName}</span>
            {order.deliveryPhone && `, ${order.deliveryPhone}`}
            {order.deliveryAddress && `, ${order.deliveryAddress}`}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-4">
          <OrderProducts orderId={id} orderUserId={order.userId || ''} />
        </div>
      </div>
    </Page>
  );
}

interface OrderProductsProps {
  orderId: string
  orderUserId: string
}

function OrderProducts({ orderId, orderUserId }: OrderProductsProps) {
  const { orderProducts, loading } = useOrderItems(orderId);
  const { order } = useOrder(orderId);

  if (loading) return <div className="p-4">Loading products...</div>;
  if (!orderProducts?.length) return <div className="p-4">No products in this order</div>;

  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-4 bg-gray-50 p-2 rounded md:flex-row md:items-center md:justify-between">
        <div className="w-full md:w-1/2 relative">
          <AddProduct orderId={orderId} orderUserId={orderUserId} />
        </div>
        <div className="flex gap-2 justify-start md:justify-end">
          <CancelButton orderId={orderId} />
          <PaidButton orderId={orderId} orderTotal={order?.totalAmount} />
        </div>
      </div>

      <Table className="w-full border-collapse">
        <Thead className="bg-gray-50">
          <Tr>
            <Th className="p-3"></Th>
            <Th className="p-3 text-left">Product</Th>
            <Th className="p-3 text-right">Price</Th>
            <Th className="p-3 text-center">Qty</Th>
            <Th className="p-3 text-right">Total</Th>
            <Th className="p-3">Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orderProducts.map((product) => (
            <OrderProductRow key={product.key} product={product} />
          ))}
        </Tbody>
        <OrderFooter orderId={orderId} />
      </Table>
    </div>
  );
}

interface OrderProductRowProps {
  product: ProductOrder
}

function updateReadyForDelivery(product: ProductOrder, ready: boolean) {
  const updatedProduct = { ...product, readyForDelivery: ready };
  useUpdateOrderItem(updatedProduct); // This updates the product in the database
}

function OrderProductRow({ product }: OrderProductRowProps) {
  const navigate = useNavigate();
  const updateQuantity = useUpdateOrderItem;
  const removeItem = useRemoveOrderItem;
  const [isEditingPrice, setIsEditingPrice] = useState(false);
  const [editedPrice, setEditedPrice] = useState(product.price);

  const handleQuantityChange = (delta: number) => {
    const newQuantity = product.quantity + delta;
    if (newQuantity < 1) return;
    updateQuantity({ ...product, quantity: newQuantity });
  };

  const handleReadyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isReady = e.target.checked;
    updateReadyForDelivery(product, isReady);
  };

  const handlePriceEdit = () => {
    setIsEditingPrice(true);
  };

  const handlePriceSave = () => {
    updateQuantity({ ...product, price: editedPrice });
    setIsEditingPrice(false);
  };

  const handlePriceCancel = () => {
    setEditedPrice(product.price);
    setIsEditingPrice(false);
  };

  const formattedPrice = new Intl.NumberFormat('ja-JP').format(product.price);
  const formattedTotal = new Intl.NumberFormat('ja-JP').format(product.quantity * product.price);

  return (
    <Tr
      className={`border-b ${product.readyForDelivery ? 'bg-green-100' : 'hover:bg-gray-50'}`}
    >
      <Td className="p-3">
        {product.thumbImage && (
          <img src={product.thumbImage} className="w-14 h-14 object-cover rounded" alt={product.nameEn} />
        )}
      </Td>
      <Td className="p-3">
        <a
          href={`/products/${product.productId}`}
          onClick={(e) => {
            e.preventDefault();
            navigate(`/products/${product.productId}`);
          }}
          className="font-semibold text-blue-600 hover:underline"
        >
          {product.nameEn}
        </a>
        <div className="text-sm text-gray-500">{product.unit}</div>
      </Td>
      <Td className="p-3 text-right">
        {isEditingPrice ? (
          <div className="flex items-center gap-2">
            <span className="text-sm">¥</span>
            <input
              type="number"
              value={editedPrice}
              onChange={(e) => setEditedPrice(parseInt(e.target.value) || 0)}
              className="w-24 p-1 border rounded"
              min="0"
            />
            <button
              onClick={handlePriceSave}
              className="text-green-600 hover:text-green-800"
            >
              ✓
            </button>
            <button
              onClick={handlePriceCancel}
              className="text-red-600 hover:text-red-800"
            >
              ✗
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-end gap-2">
            <span>¥{formattedPrice}</span>
            <button
              onClick={handlePriceEdit}
              className="text-blue-600 hover:text-blue-800 text-sm"
            >
              Edit
            </button>
          </div>
        )}
      </Td>
      <Td className="p-3">
        <div className="flex items-center justify-center gap-2">
          <button
            className="w-6 h-6 rounded-full bg-red-200 flex items-center justify-center hover:bg-red-300"
            onClick={() => handleQuantityChange(-1)}
          >
            -
          </button>
          <span>{product.quantity}</span>
          <button
            className="w-6 h-6 rounded-full bg-green-200 flex items-center justify-center hover:bg-green-300"
            onClick={() => handleQuantityChange(1)}
          >
            +
          </button>
        </div>
      </Td>
      <Td className="p-3 text-right">¥{formattedTotal}</Td>
      <Td className="p-3 flex gap-4 justify-between">
        <div className="pb-6">
          <input
            type="checkbox"
            checked={product.readyForDelivery ?? false}
            onChange={handleReadyChange}
            className="w-5 h-5 text-green-600 rounded focus:ring-green-500"
          />
          <span className="text-sm text-gray-600">Ready</span>
        </div>
        <button
          className="w-6 h-6 rounded-full bg-red-500 text-white flex items-center justify-center hover:bg-red-600"
          onClick={() => removeItem(product)}
        >
          X
        </button>
      </Td>
    </Tr>
  );
}


interface OrderFooterProps {
  orderId: string
}

function OrderFooter({ orderId }: OrderFooterProps) {
  const { order } = useOrder(orderId)
  const { otherSettings } = useOtherSettings()

  const numberFormat = new Intl.NumberFormat('ja-JP', { maximumFractionDigits: 0 })

  return (
    <tfoot>
      {order?.taxes?.map((tax: any, index: number) => (
        tax.amount > 0 && (
          <tr key={index} className="text-sm text-gray-600">
            <td colSpan={3} className="text-right p-2">Tax {tax.rate}%</td>
            <td colSpan={2} className="text-right p-2">¥{numberFormat.format(tax.amount)}</td>
          </tr>
        )
      ))}
      { /* {order?.deliveryCharge && order?.deliveryCharge > 0 && (
        <tr className="text-sm text-gray-600">
          <td colSpan={3} className="text-right p-2">Delivery Charge</td>
          <td colSpan={2} className="text-right p-2">¥{numberFormat.format(order.deliveryCharge)}</td>
        </tr>
      )}
      {order?.deliveryChargeTax && order?.deliveryChargeTax > 0 && (
        <tr className="text-sm text-gray-600">
          <td colSpan={3} className="text-right p-2">Delivery Tax {otherSettings?.shippingTax}%</td>
          <td colSpan={2} className="text-right p-2">¥{numberFormat.format(order.deliveryChargeTax)}</td>
        </tr>
      )} 
*/
      }
      <tr className="font-semibold">
        <td colSpan={3} className="text-right p-2">Total</td>
        <td colSpan={2} className="text-right p-2 text-xl">¥{numberFormat.format(order?.totalAmount || 0)}</td>
      </tr>
    </tfoot>
  )
}

interface AddProductProps {
  orderId: string
  orderUserId: string
}

function AddProduct({ orderId, orderUserId }: AddProductProps) {
  const { products } = useProducts()
  const { prices } = useGetAllPricing()
  const [productsWithPrice, setProductsWithPrice] = useState<any[]>([])
  const addOrderItem = useAddOrderItem

  useEffect(() => {
    const formattedProducts = prices?.map((p) => {
      const product = products?.find((pp) => pp.productId === p.productId)
      return {
        ...product,
        price: p?.price,
        id: p.productId,
        pricingId: p.pricingId,
        unit: p?.unit,
        name: product?.productNameEn,
      }
    }) || []
    setProductsWithPrice(formattedProducts)
  }, [products, prices])

  const handleOnSelect = useCallback(
    debounce((item: any) => {
      const productOrder = new ProductOrder()
      productOrder.additionDate = new Date().toISOString()
      productOrder.productId = item.productId
      productOrder.pricingId = item.pricingId
      productOrder.nameEn = item.productNameEn
      productOrder.nameJp = item.productNameJp
      productOrder.unit = item.unit
      productOrder.price = item.price
      productOrder.quantity = 1
      productOrder.comments = ''
      productOrder.thumbImage = item.images ? item.images['0'] : ''
      productOrder.taxRate = item.taxRate
      productOrder.userId = orderUserId
      productOrder.orderId = orderId

      addOrderItem(productOrder)
    }, 300),
    [orderId, orderUserId, addOrderItem]
  )

  const formatResult = (item: any) => (
    <div className="flex items-center gap-2">

      <div>
        <span className="font-semibold">{item.name}</span>
        <span className="text-sm text-gray-500 ml-2">{item.unit}</span>
        <span className="ml-2">¥{item.price}</span>
      </div>
    </div>
  )

  return (
    <div className="flex items-center gap-2 w-full sm:w-1/2">
      <span className="font-medium">Add:</span>
      <div className="w-full">
        <ReactSearchAutocomplete
          items={productsWithPrice}
          onSelect={handleOnSelect}
          formatResult={formatResult}
          placeholder="Search products..."
          className="w-full z-10"

        />
      </div>
    </div>
  )
}

interface CancelButtonProps {
  orderId: string
}

function CancelButton({ orderId }: CancelButtonProps) {
  const [cancelModal, setCancelModal] = useState(false)
  const [cancelReason, setCancelReason] = useState('')
  const { updateCancelled } = useUpdatePaidAmount(orderId)
  const navigate = useNavigate()

  const handleCancel = () => {
    updateCancelled(cancelReason || 'No reason provided')
    setCancelModal(false)
    navigate('/orders')
  }

  return (
    <>
      <Button color="failure" onClick={() => setCancelModal(true)}>
        Cancel Order
      </Button>
      <Modal show={cancelModal} onClose={() => setCancelModal(false)} size="md">
        <Modal.Header>Cancel Order Confirmation</Modal.Header>
        <Modal.Body>
          <div className="space-y-4">
            <p className="text-gray-600">Are you sure you want to cancel this order?</p>
            <div>
              <label className="block text-sm font-medium text-gray-700">Reason</label>
              <textarea
                className="mt-1 w-full p-2 border rounded-md"
                rows={3}
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
                placeholder="Enter cancellation reason..."
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="gray" onClick={() => setCancelModal(false)}>
            Close
          </Button>
          <Button color="failure" onClick={handleCancel}>
            Confirm Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

interface PaidButtonProps {
  orderId: string
  orderTotal?: number
}

function PaidButton({ orderId, orderTotal }: PaidButtonProps) {
  const [paidModal, setPaidModal] = useState(false)
  const [paidMethod, setPaidMethod] = useState<'cash' | 'paypay'>('cash')
  const [paidAmount, setPaidAmount] = useState(0)
  const { updatePaid } = useUpdatePaidAmount(orderId)
  const { userBalance } = useUpdateUserBalance(orderId)
  const navigate = useNavigate()

  const balance = useMemo(() =>
    calculateBalance(orderTotal || 0, paidAmount, userBalance || 0),
    [orderTotal, paidAmount, userBalance]
  )

  const handlePaid = () => {
    updatePaid(paidMethod, paidAmount)
    setPaidModal(false)
    navigate('/orders')
  }

  const numberFormat = new Intl.NumberFormat('ja-JP')

  return (
    <>
      <Button color="success" onClick={() => setPaidModal(true)}>
        Mark as Paid
      </Button>
      <Modal show={paidModal} onClose={() => setPaidModal(false)} size="md">
        <Modal.Header>Payment Confirmation</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Payment Method</label>
              <select
                className="mt-1 w-full p-2 border rounded-md"
                value={paidMethod}
                onChange={(e) => setPaidMethod(e.target.value as 'cash' | 'paypay')}
              >
                <option value="cash">Cash</option>
                <option value="paypay">PayPay</option>
              </select>
            </div>

            {paidMethod === 'paypay' && (
              <div>
                <label className="block text-sm font-medium text-gray-700">PayPay QR Code</label>
                <img
                  src="https://www.paypay.ne.jp/qr/qr.php?qrdata=00020101021129370016JP.CO.PAYPAY.QR000000000000000000520400005303392540510.005802JP5913PayPay%20QR%20Code6009Tokyo6304E0C8"
                  alt="PayPay QR Code"
                  className="mt-2 w-32 mx-auto"
                />
              </div>
            )}

            {userBalance !== undefined && (
              <div>
                <label className="block text-sm font-medium text-gray-700">User Balance</label>
                <span className={`text-lg ${userBalance < 0 ? 'text-red-500' : 'text-green-700'}`}>
                  ¥{numberFormat.format(userBalance)}
                </span>
              </div>
            )}

            {paidMethod === 'cash' && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Total Amount</label>
                  <span className="text-lg text-red-500">¥{numberFormat.format(orderTotal || 0)}</span>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Paid Amount</label>
                  <input
                    type="number"
                    className="mt-1 w-full p-2 border rounded-md"
                    value={paidAmount}
                    onChange={(e) => setPaidAmount(parseInt(e.target.value) || 0)}
                    min="0"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Balance</label>
                  <span className={`text-lg ${balance < 0 ? 'text-red-500' : 'text-green-700'}`}>
                    ¥{numberFormat.format(balance)}
                  </span>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="gray" onClick={() => setPaidModal(false)}>
            Close
          </Button>
          {paidMethod === 'cash' && paidAmount !== orderTotal && (
            <Button color="blue" onClick={handlePaid}>
              Record Partial Payment
            </Button>
          )}
          <Button color="success" onClick={handlePaid}>
            Confirm Payment
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

// Helper function
const getStatusColor = (status?: string) => {
  switch (status?.toLowerCase()) {
    case 'completed': return 'text-green-600'
    case 'pending': return 'text-yellow-600'
    case 'cancelled': return 'text-red-600'
    default: return 'text-gray-600'
  }
}
