import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Categories from './components/Categories'
import DeliveryTimeslot from './components/DeliveryTimeslots'
import PickupTimeslot from './components/PickupTimeslots'
import Home from './components/Home'
import Layout from './components/Layout'
import ProductAddEdit from './components/products/ProductAddEdit'
import SignIn from './components/SignIn'
import Landmarks from './components/Landmarks'
import OtherSetting from './components/Settings'
import Users from './components/Users'
import UserEdit from './components/UserEdit'
import CategoryAddEdit from './components/CategoriesAddEdit'
import { DBContext } from './base/DbContext'
import { useCategories, useProducts } from './base/useDbHooks'
import { Toaster } from 'react-hot-toast'
import DeliveryCharges from './components/DeliveryCharges'
import DeliveryLocations from './components/DeliveryLocations'
import Orders from './components/orders/Orders'
import OrderView from './components/orders/OrderViewEdit'
import DeliveryProducts from './components/delivery/deliveryProducts'
import { WhatsAppMessage } from './components/reports/WhatsAppMessage'
import { SalesReports } from './components/reports/SalesReports'
import { StockUpdate } from './components/products/StockUpdate'
import Products from './components/products/Products'
import { SalesReportsMonthly } from './components/reports/SalesReportsMonthly'
import { SalesReportsMonthlyProduct } from './components/reports/SalesReportsMonthlyProduct'
import { SalesReportsInvoiceList } from './components/reports/SalesReportsInvoiceList'
import { PriceUpdate } from './components/products/EditPricing'
import { LastInvoicesList } from './components/reports/LastInvoicesList'
import { StockAndPriceUpdate } from './components/products/StockAndPriceUpdate'
import { LastOnlineInvoicesList } from './components/reports/LastOnlineInvoicesList'

function App() {
  const { categories } = useCategories()
  const { products } = useProducts()
  return (
    <DBContext.Provider
      value={{
        categories,
        products,
      }}
    >
      <Toaster
        toastOptions={{
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='signin' element={<SignIn />} />
            <Route path='products' element={<Products />} />
            <Route path='products/:id' element={<ProductAddEdit />} />
            <Route path='stock' element={<StockUpdate />} />
            <Route path='pricing' element={<PriceUpdate />} />
            <Route path='stockandpricing' element={<StockAndPriceUpdate />} />
            <Route path='orders' element={<Orders />} />
            <Route path='orders/:id' element={<OrderView />} />
            <Route path='users' element={<Users />} />
            <Route path='users/:id' element={<UserEdit />} />
            <Route path='deliveryProducts' element={<DeliveryProducts />} />

            <Route path='settings/categories' element={<Categories />} />
            <Route path='settings/categories/:category' element={<CategoryAddEdit />} />
            <Route path='settings/deliveryTimeslot' element={<DeliveryTimeslot />} />
            <Route path='settings/pickupTimeslot' element={<PickupTimeslot />} />
            <Route path='settings/landmark' element={<Landmarks />} />
            <Route path='settings/deliveryCharges' element={<DeliveryCharges />} />
            <Route path='settings/deliveryLocations' element={<DeliveryLocations />} />
            <Route path='settings/otherSettings' element={<OtherSetting />} />

            <Route path='reports/whatsappmsg' element={<WhatsAppMessage />} />
            <Route path='reports/salesReports' element={<SalesReports />} />
            <Route path='reports/salesReportsInvoice' element={<SalesReportsInvoiceList />} />
            <Route path='reports/salesReportsMonthly' element={<SalesReportsMonthly />} />
            <Route
              path='reports/salesReportsMonthlyProduct'
              element={<SalesReportsMonthlyProduct />}
            />
            <Route path='reports/LastInvoicesList' element={<LastInvoicesList />} />
            <Route path='reports/LastOnlineInvoicesList' element={<LastOnlineInvoicesList />} />

            {/* <Route path='signup' element={<SignUp />} />
        <Route path='emailVerification' element={<EmailVerification />} />
        <Route path='forgotPassword' element={<ForgotPassword />} />
        <Route path='resetPassword' element={<ResetPassword />} /> */}

            {/* <Route path='*' element={<NoPage />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </DBContext.Provider>
  )
}

export default App
