import { child, get, getDatabase, push, query, ref, remove, set } from 'firebase/database'
import { useEffect, useState } from 'react'
import { useList, useListVals, useObjectVal } from 'react-firebase-hooks/database'
import { DB } from '../DB'
import { firebaseApp } from '../firebase'
import { Order, ProductOrder } from '../model/Order'
import { DeliveryCharge, OtherSettings } from '../model/Settings'
import { useDeliveryCharges, useOtherSettings, useUpdateUserBalance } from './useDbHooks'

const database = getDatabase(firebaseApp)

export const useOrders = () => {
  const [ordersSnapshots] = useList(ref(database, DB.currentOrders))
  const [orders, setOrders] = useState<Order[]>([])

  useEffect(() => {
    if (ordersSnapshots) {
      const ods = ordersSnapshots.map((s) => s?.toJSON() as Order)
      // cats.sort((a, b) => a.displayOrder - b.displayOrder)

      // unique by property orderId
      const unique = ods.filter((value, index, self) => self.findIndex((o) => o.orderId === value.orderId) === index)

      setOrders(unique)
    }
  }, [ordersSnapshots])

  return { orders }
}

export const useOrder = (id: string) => {
  const [order, loading] = useObjectVal<Order>(query(ref(database, DB.currentOrders + '/' + id)))

  return { order, loading }
}

export const useOrderItems = (id?: string) => {
  const [orderProducts, loading] = useListVals<ProductOrder>(ref(database, DB.orderProducts + '/' + id))
  const [orderedProducts, setOrderedProducts] = useState<ProductOrder[]>([])

  useEffect(() => {
    if (orderProducts) {
      // unique by property key
      const unique = orderProducts.filter((value, index, self) => self.findIndex((o) => o.key === value.key) === index)
      setOrderedProducts(unique)
    }
  }, [orderProducts])

  return {
    orderProducts: orderedProducts,
    loading,
  }
}

export const useUpdateOrderItem = (productOrder: ProductOrder) => {
  set(
    ref(database, DB.orderProducts + '/' + productOrder.orderId + '/' + productOrder.key),
    productOrder,
  )
}

export const useRemoveOrderItem = (productOrder: ProductOrder) => {
  set(ref(database, DB.orderProducts + '/' + productOrder.orderId + '/' + productOrder.key), null)
}

export const useAddOrderItem = (productOrder: ProductOrder) => {
  const newKey = push(child(ref(database), DB.orderProducts + '/' + productOrder.orderId)).key
  productOrder.key = newKey
  set(
    ref(database, DB.orderProducts + '/' + productOrder.orderId + '/' + productOrder.key),
    productOrder,
  )
}

export const getDeliveryCharge = (totalAmount: number, deliveryCharges: DeliveryCharge[]) => {
  const deliveryCharge = deliveryCharges.find((d) => d.range >= totalAmount);
  const charge = deliveryCharge?.deliveryCharge ?? 0;
  return Number(charge); // Convert string or number to number
};

export const getTotalAndTax = (
  productOrders: ProductOrder[],
  otherSettings: OtherSettings | undefined,
  isDeliveryChargesApplicable: boolean,
  deliveryCharges?: DeliveryCharge[],
) => {
  let subTotalAmount = 0; // Pre-tax total
  let totalTaxAmount = 0; // Total tax extracted from prices
  const taxAmountByRate = new Map<number, number>(); // Tax by rate for breakdown
  let deliveryCharge = 0;
  let deliveryChargeTaxAmount = 0;

  // Calculate subtotal and tax from tax-included prices
  productOrders.forEach((p) => {
    const taxRate = (p.taxRate ?? 0) * 0.01; // Convert percentage to decimal (e.g., 10% -> 0.1)
    const totalPrice = p.price * p.quantity; // Tax-included total for this product

    // Extract tax amount from tax-included price
    const preTaxAmount = totalPrice / (1 + taxRate); // Price before tax
    const taxAmount = totalPrice - preTaxAmount; // Tax portion

    subTotalAmount += preTaxAmount; // Add pre-tax amount to subtotal
    totalTaxAmount += taxAmount; // Add tax to total tax

    // Accumulate tax by rate for breakdown
    const taxAmountByRateValue = taxAmountByRate.get(p.taxRate ?? 0) ?? 0;
    taxAmountByRate.set(p.taxRate ?? 0, taxAmountByRateValue + taxAmount);
  });

  // Handle delivery charges if applicable
  if (isDeliveryChargesApplicable && deliveryCharges) {
    deliveryCharge = getDeliveryCharge(subTotalAmount + totalTaxAmount, deliveryCharges);
    const shippingTax = otherSettings?.shippingTax ?? 0;
    deliveryChargeTaxAmount = deliveryCharge * (shippingTax * 0.01);
  }

  // Total amount is the sum of all tax-included product prices plus delivery charges
  console.log('getTotalAndTax - input:', { subTotalAmount, totalTaxAmount, deliveryCharge, deliveryChargeTaxAmount });
  const totalAmount = subTotalAmount + totalTaxAmount + deliveryCharge + deliveryChargeTaxAmount;

  console.log('getTotalAndTax - calculated:', {
    subTotalAmount,
    totalTaxAmount,
    taxAmountByRate,
    deliveryCharge,
    deliveryChargeTaxAmount,
    totalAmount,
  });

  return {
    subTotalAmount, // Pre-tax total
    totalTaxAmount, // Total tax extracted
    taxAmountByRate, // Tax breakdown by rate
    deliveryCharge,
    deliveryChargeTaxAmount,
    totalAmount, // Final total including everything
  };
};


export const useUpdateOrderTotals = (orderId: string) => {
  const { orderProducts } = useOrderItems(orderId);
  const { otherSettings } = useOtherSettings();
  const { deliveryCharges } = useDeliveryCharges();
  const { order } = useOrder(orderId);

  useEffect(() => {
    if (orderProducts && order) {
      const {
        subTotalAmount,
        totalTaxAmount,
        taxAmountByRate,
        deliveryCharge,
        deliveryChargeTaxAmount,
        totalAmount,
      } = getTotalAndTax(orderProducts, otherSettings, true, deliveryCharges);

      console.log('useUpdateOrderTotals - updating totals:', {
        subTotalAmount,
        totalTaxAmount,
        totalAmount,
        deliveryCharge,
        deliveryChargeTaxAmount,
      });

      // Always update totals in the database
      set(ref(database, DB.currentOrders + '/' + orderId + '/subTotal'), subTotalAmount);
      set(ref(database, DB.currentOrders + '/' + orderId + '/taxAmount'), totalTaxAmount);
      set(ref(database, DB.currentOrders + '/' + orderId + '/deliveryCharge'), deliveryCharge);
      set(ref(database, DB.currentOrders + '/' + orderId + '/deliveryChargeTaxAmount'), deliveryChargeTaxAmount);
      set(ref(database, DB.currentOrders + '/' + orderId + '/totalAmount'), totalAmount);

      const taxes = Array.from(taxAmountByRate, ([key, value]) => ({ rate: key, amount: value }));
      set(ref(database, DB.currentOrders + '/' + orderId + '/taxes'), taxes);
    }
  }, [orderProducts, otherSettings, deliveryCharges, order, orderId]);
};


export const useMoveOrderedProductsToOldProducts = (orderId: string) => {
  const { orderProducts } = useOrderItems(orderId)

  const moveProducts = () => {
    if (orderProducts) {
      set(ref(database, DB.invoiceProducts + '/' + orderId), orderProducts)
      remove(ref(database, DB.orderProducts + '/' + orderId))
    }
  }

  const moveProductsToCancelled = () => {
    if (orderProducts) {
      set(ref(database, DB.cancelledProducts + '/' + orderId), orderProducts)
      remove(ref(database, DB.orderProducts + '/' + orderId))
    }
  }

  return { moveProducts, moveProductsToCancelled }
}

export const calculateBalance = (totalAmount: number, paidAmount: number, userBalance: number) => {
  return (totalAmount - userBalance - paidAmount) * -1
}

export const useInvoiceIdGenerator = () => {
  const [invoices] = useObjectVal<number>(ref(database, DB.invoiceId))

  const getInvoiceId = () => {
    const invoiceId = invoices ?? 1
    set(ref(database, DB.invoiceId), invoiceId + 1)
    return invoiceId
  }

  return { getInvoiceId }
}

export const useUpdatePaidAmount = (orderId: string) => {
  const { order } = useOrder(orderId)
  const { moveProducts, moveProductsToCancelled } = useMoveOrderedProductsToOldProducts(orderId)
  const { userBalance, updateBalance } = useUpdateUserBalance(order?.userId ?? '')
  const { getInvoiceId } = useInvoiceIdGenerator()

  const updatePaid = (paidMethod: 'cash' | 'paypay', paidAmount: number) => {
    if (order) {
      const balanceAmount = calculateBalance(order.totalAmount, paidAmount, userBalance ?? 0)
      order.paidAmount = paidAmount
      order.balanceAmount = balanceAmount
      order.paidMethod = paidMethod
      order.orderStatus = 'completed' // Set orderStatus to "completed" when paid

      console.log('useUpdatePaidAmount', order)

      order.invoiceDate = '' + Date.now()
      order.invoiceId = 'RBInv' + getInvoiceId()

      // Save the updated order to the invoice collection
      set(ref(database, DB.invoice + '/' + order.invoiceId), order)
      remove(ref(database, DB.currentOrders + '/' + orderId))

      // Move ordered products to old products
      moveProducts()
      updateBalance(balanceAmount)
    }

    // move to paid orders to paid orders
  }

  const updateCancelled = (cancelReason: string) => {
    if (order) {
      order.cancelledDate = '' + Date.now()
      order.cancelReason = cancelReason
      order.orderStatus = 'cancelled' // Optionally set status to "cancelled" here as well
      set(ref(database, DB.cancelledOrders + '/' + orderId), order)
      remove(ref(database, DB.currentOrders + '/' + orderId))

      moveProductsToCancelled()
    }

    // move to paid orders to paid orders
  }

  return { order, updatePaid, updateCancelled }
}

export const useGetAllOrderedProducts = () => {
  const [orderedProductsList, loading, error] = useObjectVal<any[]>(
    ref(database, DB.orderProducts),
  ) as any
  const [orderedProducts, setOrderedProducts] = useState<ProductOrder[]>([])

  useEffect(() => {
    if (orderedProductsList) {
      const keys = Object.keys(orderedProductsList ?? {})
      const productsMap = new Map()
      for (const key of keys) {
        const subKeys = Object.keys(orderedProductsList[key])
        for (const subKey of subKeys) {
          const product = orderedProductsList[key][subKey]
          const id = product.productId + '-' + product.unit
          if (productsMap.has(id)) {
            const existingProduct = productsMap.get(id)
            existingProduct.quantity += product.quantity
            productsMap.set(id, existingProduct)
          } else {
            productsMap.set(id, product)
          }
        }
      }
      setOrderedProducts(Array.from(productsMap.values()))
    }
  }, [orderedProductsList, loading])

  return { orderedProducts }
}
