
// users/:id/orders 
export class Order {
  orderDate!: string | Date | object
  orderId!: string | null
  userId!: string | null
  invoiceId!: string | null
  invoiceDate!: string
  cancelledDate!: string | Date | object
  cancelReason!: string

  subTotal!: number
  taxes!: any[]
  taxAmount!: number
  deliveryCharge!: number
  deliveryChargeTax!: number | undefined
  deliveryChargeTaxAmount!: number
  totalAmount!: number

  balanceAmount!: number
  paidAmount!: number
  paidMethod!: 'cash' | 'paypay' | ''
  deliveryTimeslot!: string | undefined
  deliveryDate!: string | Date | object
  deliveryAddress!: string
  deliveryName!: string | undefined
  deliveryPhone!: string | undefined
  pickupTimeslot!: string | undefined
  comments!: string
  orderStatus!: 'pending' | 'confirmed' | 'cancelled' | 'completed'
}

export class ProductOrder {
  key!: string | null
  additionDate!: string | Date
  userId!: string | null | undefined
  orderId!: string | null | undefined
  productId!: string
  pricingId!: string
  nameEn!: string
  nameJp!: string
  unit!: string
  price!: number
  quantity!: number
  comments!: string
  thumbImage!: string
  taxRate!: number | undefined
  readyForDelivery?: boolean = false; // Add this field, default to false
  // taxAmount!: number
}


export class OrderDirect {
  orderDate!: string | Date | object;
  orderId!: string | null;
  invoiceId!: string | null;
  invoiceDate!: string;
  cancelledDate!: string | Date | object;
  cancelReason!: string;

  subTotal!: number;
  taxes!: any[];
  taxAmount!: number;
  totalAmount!: number;

  discountAmount!: number;
  discountPercent!: number;

  balanceAmount!: number;
  paidAmount!: number;
  paidMethod!: 'cash' | 'paypay' | '';
  comments!: string;
  orderStatus!: 'pending' | 'confirmed' | 'cancelled' | 'completed';
}

export class ProductOrderDirect {
  id: string | null = null;
  key!: string | null;
  additionDate!: string | Date;
  orderId!: string | null | undefined;
  productId!: string;
  pricingId!: string;
  nameEn!: string;
  nameJp!: string;
  unit!: string;
  price!: number;
  quantity!: number;
  comments!: string;
  thumbImage!: string;
  taxRate!: number | undefined;
  designCharge!: number;
  isDesignChargeable!: boolean | undefined;
  // taxAmount!: number
}