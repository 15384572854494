import { child, get, getDatabase, ref } from 'firebase/database'
import { useList } from 'react-firebase-hooks/database'
import { firebaseApp } from '../firebase'
import { DB } from '../DB'
import { useEffect, useState } from 'react'
import { Order, OrderDirect, ProductOrder, ProductOrderDirect } from '../model/Order'

const database = getDatabase(firebaseApp)

function addAmountToMap(map: Map<string, number>, key: string, amount: number) {
  map.set(key, (map.get(key) ?? 0) + amount)
}

function addOrderToMapArray<T>(map: Map<string, T[]>, key: string, order: T) {
  map.set(key, [...(map.get(key) ?? []), order])
}

function addProductQuantityToMap(
  map: Map<string, Map<string, ProductOrderWithQuantity>>,
  {
    key,
    productId,
    unit,
    quantity,
    price,
    taxRate,
  }: {
    key: string
    productId: string
    unit: string
    quantity: number
    price: number
    taxRate: number
  },
) {
  const productMap = map.get(key) ?? new Map<string, ProductOrderWithQuantity>()

  productMap.set(productId, {
    ...(productMap.get(productId) ?? ({} as ProductOrderWithQuantity)),
    productId: productId,
    unit: unit,
    price: price,
    taxRate: taxRate,
    quantity: (productMap.get(productId)?.quantity ?? 0) + quantity,
    amount: (productMap.get(productId)?.amount ?? 0) + quantity * price,
    tax: (productMap.get(productId)?.tax ?? 0) + quantity * price * (taxRate / 100),
  })

  map.set(key, productMap)
}

export interface ProductOrderWithQuantity {
  productId: string
  orderId: string
  unit: string
  price: number
  taxRate: number
  quantity: number
  amount: number
  tax: number
}

export const useDbReports = (year: string = new Date().getFullYear().toString()) => {
  const [invoicesSnapshots] = useList(ref(database, DB.invoice))
  const [invoices, setInvoices] = useState<Order[]>([])
  const [invoicesPerDate, setInvoicesPerDate] = useState<Map<string, Order[]>>(new Map())
  const [invoiceTotalPerDate, setInvoiceTotalPerDate] = useState<Map<string, number>>(new Map())
  const [invoiceTotalTaxPerDate, setInvoiceTotalTaxPerDate] = useState<Map<string, number>>(
    new Map(),
  )
  const [invoiceTotalAmount, setInvoiceTotalAmount] = useState<number>(0)
  const [invoiceTotalTax, setInvoiceTotalTax] = useState<number>(0)

  const [directInvoicesSnapshots] = useList(ref(database, DB.directInvoice))
  const [directInvoices, setDirectInvoices] = useState<OrderDirect[]>([])
  const [directInvoicesPerDate, setDirectInvoicesPerDate] = useState<Map<string, OrderDirect[]>>(
    new Map(),
  )
  const [directInvoiceTotalPerDate, setDirectInvoiceTotalPerDate] = useState<Map<string, number>>(
    new Map(),
  )
  const [directInvoiceTotalTaxPerDate, setDirectInvoiceTotalTaxPerDate] = useState<
    Map<string, number>
  >(new Map())

  const [directInvoiceTotalAmount, setDirectInvoiceTotalAmount] = useState<number>(0)
  const [directInvoiceTotalTax, setDirectInvoiceTotalTax] = useState<number>(0)

  useEffect(() => {
    if (invoicesSnapshots && invoices.length === 0) {
      let ods = invoicesSnapshots.map((s) => s?.toJSON() as Order)
      ods = Object.values(ods.reduce((acc, obj) => ({ ...acc, [obj.orderId ?? '']: obj }), {}))
      // cats.sort((a, b) => a.displayOrder - b.displayOrder)

      let _totalAmount = 0
      let _totalTax = 0

      // invoices Per Date
      for (const o of ods) {
        const invoiceDate = new Date(parseInt(o.invoiceDate))
        const date = invoiceDate?.toISOString().split('T')[0]

        if (invoiceDate.getFullYear() !== parseInt(year)) {
          continue
        }

        addOrderToMapArray<Order>(invoicesPerDate, date, o)

        // invoice total
        addAmountToMap(invoiceTotalPerDate, date, o.totalAmount)
        addAmountToMap(invoiceTotalTaxPerDate, date, o.taxAmount)

        _totalAmount += o.totalAmount
        _totalTax += o.taxAmount
      }

      setInvoicesPerDate(invoicesPerDate)
      setInvoiceTotalPerDate(invoiceTotalPerDate)
      setInvoiceTotalTaxPerDate(invoiceTotalTaxPerDate)

      setInvoiceTotalAmount(_totalAmount)
      setInvoiceTotalTax(_totalTax)

      setInvoices(ods)
      // setInvoicesPerDate(odsPerDate);
      // setInvoiceTotalPerDate(invoiceTotalPerDate);
      // setInvoiceTotalTaxPerDate(invoiceTotalTaxPerDate);
    }
  }, [invoicesSnapshots, year])

  useEffect(() => {
    if (directInvoicesSnapshots) {
      let ods = directInvoicesSnapshots.map((s) => s?.toJSON() as OrderDirect)
      ods = Object.values(ods.reduce((acc, obj) => ({ ...acc, [obj.invoiceId ?? '']: obj }), {}))
      // cats.sort((a, b) => a.displayOrder - b.displayOrder)

      let _totalAmount = 0
      let _totalTax = 0

      // direct invoices Per Date
      for (const o of ods) {
        const invoiceDate = new Date(parseInt(o.invoiceDate))
        const date = invoiceDate?.toISOString().split('T')[0]

        if (invoiceDate.getFullYear() !== parseInt(year)) {
          continue
        }

        addOrderToMapArray<OrderDirect>(directInvoicesPerDate, date, o)

        // direct invoice total
        addAmountToMap(directInvoiceTotalPerDate, date, o.totalAmount)
        addAmountToMap(directInvoiceTotalTaxPerDate, date, o.taxAmount)

        _totalAmount += o.totalAmount
        _totalTax += o.taxAmount
      }

      setDirectInvoicesPerDate(directInvoicesPerDate)
      setDirectInvoiceTotalPerDate(directInvoiceTotalPerDate)
      setDirectInvoiceTotalTaxPerDate(directInvoiceTotalTaxPerDate)

      setDirectInvoiceTotalAmount(_totalAmount)
      setDirectInvoiceTotalTax(_totalTax)

      setDirectInvoices(ods)
    }
  }, [directInvoicesSnapshots, year])

  return {
    invoices,
    invoicesPerDate,
    invoiceTotalPerDate,
    invoiceTotalTaxPerDate,
    invoiceTotalAmount,
    invoiceTotalTax,
    directInvoices,
    directInvoicesPerDate,
    directInvoiceTotalPerDate,
    directInvoiceTotalTaxPerDate,
    directInvoiceTotalAmount,
    directInvoiceTotalTax,
  }
}

export const useDbReportsInvoiceList = (year: string = new Date().getFullYear().toString()) => {
  const [invoicesSnapshots] = useList(ref(database, DB.invoice))
  const [invoices, setInvoices] = useState<Order[]>([])
  const [invoiceTotalAmount, setInvoiceTotalAmount] = useState<number>(0)
  const [invoiceTotalTax, setInvoiceTotalTax] = useState<number>(0)

  const [directInvoicesSnapshots] = useList(ref(database, DB.directInvoice))
  const [directInvoices, setDirectInvoices] = useState<OrderDirect[]>([])
  const [directInvoiceTotalAmount, setDirectInvoiceTotalAmount] = useState<number>(0)
  const [directInvoiceTotalTax, setDirectInvoiceTotalTax] = useState<number>(0)

  useEffect(() => {
    if (invoicesSnapshots && invoices.length === 0) {
      let ods = invoicesSnapshots.map((s) => s?.toJSON() as Order)
      ods = Object.values(ods.reduce((acc, obj) => ({ ...acc, [obj.orderId ?? '']: obj }), {}))

      let _totalAmount = 0
      let _totalTax = 0
      const invoicesForYear = []

      // invoices Per Date
      for (const o of ods) {
        const invoiceDate = new Date(parseInt(o.invoiceDate))
        if (invoiceDate.getFullYear() !== parseInt(year)) {
          continue
        }
        _totalAmount += o.totalAmount
        _totalTax += o.taxAmount
        invoicesForYear.push(o)
      }

      setInvoiceTotalAmount(_totalAmount)
      setInvoiceTotalTax(_totalTax)
      invoicesForYear.sort(
        (a, b) => new Date(a.orderDate as any).getTime() - new Date(b.orderDate as any).getTime(),
      )

      setInvoices(invoicesForYear)
    }
  }, [invoicesSnapshots, year])
  useEffect(() => {
    if (directInvoicesSnapshots) {
      let ods = directInvoicesSnapshots.map((s) => s?.toJSON() as OrderDirect)
      ods = Object.values(ods.reduce((acc, obj) => ({ ...acc, [obj.invoiceId ?? '']: obj }), {}))

      let _totalAmount = 0
      let _totalTax = 0
      const invoicesForYear = []

      // direct invoices Per Date
      for (const o of ods) {
        const invoiceDate = new Date(parseInt(o.invoiceDate))
        if (invoiceDate.getFullYear() !== parseInt(year)) {
          continue
        }

        _totalAmount += o.totalAmount
        _totalTax += o.taxAmount
        invoicesForYear.push(o)
      }

      setDirectInvoiceTotalAmount(_totalAmount)
      setDirectInvoiceTotalTax(_totalTax)

      invoicesForYear.sort(
        (a, b) => new Date(a.invoiceDate).getTime() - new Date(b.invoiceDate).getTime(),
      )
      setDirectInvoices(invoicesForYear)
    }
  }, [directInvoicesSnapshots, year])

  return {
    invoices,
    invoiceTotalAmount,
    invoiceTotalTax,
    directInvoices,
    directInvoiceTotalAmount,
    directInvoiceTotalTax,
  }
}

export const useDbReportsInvoiceLastList = (numberOfInvoices = 10) => {
  const [directInvoicesSnapshots] = useList(ref(database, DB.directInvoice))
  const [directInvoices, setDirectInvoices] = useState<OrderDirect[]>([])
  const [last10DirectInvoices, setLast10DirectInvoices] = useState<OrderDirect[]>([])
  const [last10DirectInvoicesIds, setLast10DirectInvoicesIds] = useState<string[]>([])

  useEffect(() => {
    if (directInvoicesSnapshots) {
      let ods = directInvoicesSnapshots.map((s) => s?.toJSON() as OrderDirect)
      ods = Object.values(ods.reduce((acc, obj) => ({ ...acc, [obj.invoiceId ?? '']: obj }), {}))

      const invoicesForYear = []

      for (const o of ods) {
        invoicesForYear.push(o)
      }

      invoicesForYear.sort(
        (a, b) => new Date(a.invoiceDate).getTime() - new Date(b.invoiceDate).getTime(),
      )
      setDirectInvoices(invoicesForYear)
      const lastInvoices = invoicesForYear.slice(invoicesForYear.length - numberOfInvoices)
      setLast10DirectInvoices(lastInvoices)
      const lastInvoicesIds = lastInvoices
        .map((i) => i.invoiceId)
        .filter((i) => i !== undefined && i !== '') as string[]
      setLast10DirectInvoicesIds(lastInvoicesIds)
    }
  }, [directInvoicesSnapshots])

  return {
    directInvoices,
    last10DirectInvoices,
    last10DirectInvoicesIds,
  }
}

export const useDbReportsGetInvoicesAndProducts = (count = 10) => {
  const { last10DirectInvoices, last10DirectInvoicesIds } = useDbReportsInvoiceLastList(count)
  const [productsByInvoiceId, setProductsByInvoiceId] = useState<
    Record<string, ProductOrder[] | ProductOrderDirect[]>
  >({})

  useEffect(() => {
    const fetchProducts = async () => {
      const groupedProducts: Record<string, ProductOrder[] | ProductOrderDirect[]> = {}

      for (const invoiceId of last10DirectInvoicesIds) {
        const products = (await getOrderProducts(DB.directOrderProducts, invoiceId).then((s) =>
          Object.values(s),
        )) as ProductOrder[] | ProductOrderDirect[]

        groupedProducts[invoiceId] = products
      }

      setProductsByInvoiceId(groupedProducts)
    }

    if (last10DirectInvoicesIds.length > 0) {
      fetchProducts()
    }
  }, [last10DirectInvoicesIds])

  return { last10DirectInvoices, productsByInvoiceId }
}

export const useDbReportsOnlineInvoiceLastList = (numberOfInvoices = 10) => {
  const [onlineInvoicesSnapshots] = useList(ref(database, DB.invoice))
  const [onlineInvoices, setDirectInvoices] = useState<Order[]>([])
  const [last10OnlineInvoices, setLast10DirectInvoices] = useState<Order[]>([])
  const [last10OnlineInvoicesIds, setLast10DirectInvoicesIds] = useState<string[]>([])

  useEffect(() => {
    if (onlineInvoicesSnapshots) {
      let ods = onlineInvoicesSnapshots.map((s) => s?.toJSON() as Order)
      ods = Object.values(ods.reduce((acc, obj) => ({ ...acc, [obj.invoiceId ?? '']: obj }), {}))

      const invoicesForYear = []

      for (const o of ods) {
        invoicesForYear.push(o)
      }

      invoicesForYear.sort(
        (a, b) => parseInt(a.invoiceDate) - parseInt(b.invoiceDate),
      )

      setDirectInvoices(invoicesForYear)
      const lastInvoices = invoicesForYear.slice(invoicesForYear.length - numberOfInvoices)
      setLast10DirectInvoices(lastInvoices)
      const lastInvoicesIds = lastInvoices
        .map((i) => i.orderId)
        .filter((i) => i !== undefined && i !== '') as string[]

      setLast10DirectInvoicesIds(lastInvoicesIds)
    }
  }, [onlineInvoicesSnapshots])

  return {
    onlineInvoices,
    last10OnlineInvoices,
    last10OnlineInvoicesIds,
  }
}

export const useDbReportsGetOnlineInvoicesAndProducts = (count = 10) => {
  const { last10OnlineInvoices, last10OnlineInvoicesIds } = useDbReportsOnlineInvoiceLastList(count)
  const [productsByOrderId, setProductsByOrderId] = useState<
    Record<string, ProductOrder[] | ProductOrderDirect[]>
  >({})

  useEffect(() => {
    const fetchProducts = async () => {
      const groupedProducts: Record<string, ProductOrder[] | ProductOrderDirect[]> = {}

      for (const invoiceId of last10OnlineInvoicesIds) {
        console.log('invoiceId', invoiceId)
        const products = (await getOrderProducts(DB.invoiceProducts, invoiceId).then((s) =>
          Object.values(s),
        )) as ProductOrder[] | ProductOrderDirect[]

        groupedProducts[invoiceId] = products
      }

      console.log('groupedProducts', groupedProducts)
      setProductsByOrderId(groupedProducts)
    }

    if (last10OnlineInvoicesIds.length > 0) {
      fetchProducts()
    }
  }, [last10OnlineInvoicesIds])

  return { last10OnlineInvoices, productsByOrderId}
}

export const useDbReportsMonthly = (year: string = new Date().getFullYear().toString()) => {
  const [invoicesSnapshots] = useList(ref(database, DB.invoice))
  const [invoices, setInvoices] = useState<Order[]>([])
  const [invoiceTotalPerMonth, setInvoiceTotalPerMonth] = useState<Map<string, number>>(new Map())
  const [invoiceTotalTaxPerMonth, setInvoiceTotalTaxPerMonth] = useState<Map<string, number>>(
    new Map(),
  )
  const [invoiceTotalAmount, setInvoiceTotalAmount] = useState<number>(0)
  const [invoiceTotalTax, setInvoiceTotalTax] = useState<number>(0)

  const [directInvoicesSnapshots] = useList(ref(database, DB.directInvoice))
  const [directInvoices, setDirectInvoices] = useState<OrderDirect[]>([])

  const [directInvoiceTotalPerMonth, setDirectInvoiceTotalPerMonth] = useState<Map<string, number>>(
    new Map(),
  )
  const [directInvoiceTotalTaxPerMonth, setDirectInvoiceTotalTaxPerMonth] = useState<
    Map<string, number>
  >(new Map())

  const [directInvoiceTotalAmount, setDirectInvoiceTotalAmount] = useState<number>(0)
  const [directInvoiceTotalTax, setDirectInvoiceTotalTax] = useState<number>(0)

  useEffect(() => {
    if (invoicesSnapshots && invoices.length === 0) {
      let ods = invoicesSnapshots.map((s) => s?.toJSON() as Order)
      ods = Object.values(ods.reduce((acc, obj) => ({ ...acc, [obj.orderId ?? '']: obj }), {}))
      // cats.sort((a, b) => a.displayOrder - b.displayOrder)

      let _totalAmount = 0
      let _totalTax = 0

      // invoices Per Date
      for (const o of ods) {
        const invoiceDate = new Date(parseInt(o.invoiceDate))
        const yearAndMonth = invoiceDate?.toISOString().substring(0, 7)

        if (invoiceDate.getFullYear() !== parseInt(year)) {
          continue
        }
        addAmountToMap(invoiceTotalPerMonth, yearAndMonth, o.totalAmount)
        addAmountToMap(invoiceTotalTaxPerMonth, yearAndMonth, o.taxAmount)

        _totalAmount += o.totalAmount
        _totalTax += o.taxAmount
      }

      setInvoiceTotalPerMonth(invoiceTotalPerMonth)
      setInvoiceTotalTaxPerMonth(invoiceTotalTaxPerMonth)

      setInvoiceTotalAmount(_totalAmount)
      setInvoiceTotalTax(_totalTax)

      setInvoices(ods)
      // setInvoicesPerDate(odsPerDate);
      // setInvoiceTotalPerDate(invoiceTotalPerDate);
      // setInvoiceTotalTaxPerDate(invoiceTotalTaxPerDate);
    }
  }, [invoicesSnapshots])

  useEffect(() => {
    if (directInvoicesSnapshots) {
      let ods = directInvoicesSnapshots.map((s) => s?.toJSON() as OrderDirect)
      ods = Object.values(ods.reduce((acc, obj) => ({ ...acc, [obj.invoiceId ?? '']: obj }), {}))
      // cats.sort((a, b) => a.displayOrder - b.displayOrder)

      let _totalAmount = 0
      let _totalTax = 0

      // direct invoices Per Date
      for (const o of ods) {
        const invoiceDate = new Date(parseInt(o.invoiceDate))
        const yearAndMonth = invoiceDate?.toISOString().substring(0, 7)

        if (invoiceDate.getFullYear() !== parseInt(year)) {
          continue
        }

        addAmountToMap(directInvoiceTotalPerMonth, yearAndMonth, o.totalAmount)
        addAmountToMap(directInvoiceTotalTaxPerMonth, yearAndMonth, o.taxAmount)

        _totalAmount += o.totalAmount
        _totalTax += o.taxAmount
      }

      setDirectInvoiceTotalPerMonth(directInvoiceTotalPerMonth)
      setDirectInvoiceTotalTaxPerMonth(directInvoiceTotalTaxPerMonth)

      setDirectInvoiceTotalAmount(_totalAmount)
      setDirectInvoiceTotalTax(_totalTax)

      setDirectInvoices(ods)
    }
  }, [directInvoicesSnapshots])

  return {
    invoices,
    invoiceTotalPerMonth,
    invoiceTotalTaxPerMonth,
    invoiceTotalAmount,
    invoiceTotalTax,
    directInvoices,
    directInvoiceTotalPerMonth,
    directInvoiceTotalTaxPerMonth,
    directInvoiceTotalAmount,
    directInvoiceTotalTax,
  }
}

const getOrderProducts = async (url: string, invoiceId: string) => {
  console.log('getOrderProducts', url, invoiceId)
  const snapshot = await get(child(ref(database), url + '/' + invoiceId))
  if (snapshot.exists()) {
    return snapshot.val()
  }
}

const getAllOrderProducts = async (
  url: string,
  invoiceIds: string[],
): Promise<ProductOrder[] | ProductOrderDirect[]> => {
  // fetch all data first
  const allData = await Promise.all(invoiceIds.map((invoiceId) => getOrderProducts(url, invoiceId)))
  // flat the array
  return allData.flat() as any
}

const getAllOrderProductsDirect = async (
  url: string,
  invoiceIds: string[],
): Promise<ProductOrder[] | ProductOrderDirect[]> => {
  // fetch all data first
  const allData = await Promise.all(
    invoiceIds.map((invoiceId) => getOrderProducts(url, invoiceId).then((s) => Object.values(s))),
  )
  // flat the array
  return allData.flat() as any
}

export const useDbReportsMonthlyProduct = (year: string = new Date().getFullYear().toString()) => {
  const [invoicesSnapshots] = useList(ref(database, DB.invoice))

  const [invoices, setInvoices] = useState<Order[]>([])
  const [invoiceTotalQuantityPerProduct, setInvoiceTotalQuantityPerProduct] = useState<
    Map<string, Map<string, ProductOrderWithQuantity>>
  >(new Map())

  const [directInvoicesSnapshots] = useList(ref(database, DB.directInvoice))
  // const [directInvoicesProductSnapshots] = useList(ref(database, DB.directOrderProducts))
  const [directInvoices, setDirectInvoices] = useState<OrderDirect[]>([])

  const [directInvoiceTotalQuantityPerProduct, setDirectInvoiceTotalQuantityPerProduct] = useState<
    Map<string, Map<string, ProductOrderWithQuantity>>
  >(new Map())

  useEffect(() => {
    if (invoicesSnapshots && invoices.length === 0) {
      let ods = invoicesSnapshots.map((s) => s?.toJSON() as Order)
      ods = Object.values(ods.reduce((acc, obj) => ({ ...acc, [obj.orderId ?? '']: obj }), {}))
      // get all invoice ids for this year
      const invoiceIds: string[] = []
      const invoicesAndDates: Map<string, string> = new Map()
      for (const o of ods) {
        const invoiceDate = new Date(parseInt(o.invoiceDate))
        if (invoiceDate.getFullYear() !== parseInt(year)) {
          continue
        }
        if (o.orderId && o.invoiceDate) {
          invoiceIds.push(o.orderId)
          invoicesAndDates.set(o.orderId, invoiceDate.toISOString().substring(0, 7))
        }
      }
      getAllOrderProducts(DB.invoiceProducts, invoiceIds).then((allProducts) => {
        const invoiceTotalQuantityPerProduct: Map<
          string,
          Map<string, ProductOrderWithQuantity>
        > = new Map()
        for (const p of allProducts) {
          const date = p?.orderId ? invoicesAndDates.get(p.orderId) : undefined
          if (date) {
            addProductQuantityToMap(invoiceTotalQuantityPerProduct, {
              key: date,
              productId: p.nameEn + ' - ' + p.unit + ' - ¥' + p.price + ' - ' + p.taxRate + '%',
              unit: p.unit,
              quantity: p.quantity,
              price: p.price,
              taxRate: p.taxRate ?? 0,
            })
          }
        }
        setInvoiceTotalQuantityPerProduct(invoiceTotalQuantityPerProduct)
      })
      setInvoices(ods)
    }
  }, [invoicesSnapshots])

  useEffect(() => {
    if (directInvoicesSnapshots) {
      let ods = directInvoicesSnapshots.map((s) => s?.toJSON() as OrderDirect)
      ods = Object.values(ods.reduce((acc, obj) => ({ ...acc, [obj.invoiceId ?? '']: obj }), {}))
      // cats.sort((a, b) => a.displayOrder - b.displayOrder)

      const invoiceIds: string[] = []
      const invoiceIdsAndDates: Map<string, string> = new Map()

      for (const o of ods) {
        const invoiceDate = new Date(parseInt(o.invoiceDate))

        if (invoiceDate.getFullYear() !== parseInt(year)) {
          continue
        }

        if (o.orderId && o.invoiceId && o.invoiceDate) {
          invoiceIds.push(o.invoiceId)
          invoiceIdsAndDates.set(o.orderId, invoiceDate.toISOString().substring(0, 7))
        }
      }

      getAllOrderProductsDirect(DB.directOrderProducts, invoiceIds).then((allProducts) => {
        const directInvoiceTotalQuantityPerProduct: Map<
          string,
          Map<string, ProductOrderWithQuantity>
        > = new Map()

        for (const p of allProducts) {
          const date = p?.orderId ? invoiceIdsAndDates.get(p?.orderId) : undefined
          if (date) {
            addProductQuantityToMap(directInvoiceTotalQuantityPerProduct, {
              key: date,
              productId: p.nameEn + ' - ' + p.unit + ' - ¥' + p.price + ' - ' + p.taxRate + '%',
              unit: p.unit,
              quantity: p.quantity,
              price: p.price,
              taxRate: p.taxRate ?? 0,
            })
          }
        }
        setDirectInvoiceTotalQuantityPerProduct(directInvoiceTotalQuantityPerProduct)
      })

      setDirectInvoices(ods)
    }
  }, [directInvoicesSnapshots])

  return {
    invoices,
    invoiceTotalQuantityPerProduct,
    // invoiceTotalPerMonth,
    // invoiceTotalTaxPerMonth,
    directInvoices,
    directInvoiceTotalQuantityPerProduct,
    // directInvoiceTotalPerMonth,
    // directInvoiceTotalTaxPerMonth,
  }
}
