import { useRef } from 'react'
import { format } from 'date-fns'
import { useDbReportsGetOnlineInvoicesAndProducts } from '../../base/useDbReports'
import { Order, OrderDirect } from '../../model/Order'
import { useReactToPrint } from 'react-to-print'
import { toPng } from 'html-to-image'
import { useParams } from 'react-router-dom'

export function LastOnlineInvoicesList() {
  const { count } = useParams()
  const { last10OnlineInvoices, productsByOrderId } = useDbReportsGetOnlineInvoicesAndProducts(
    count ? Number(count) : 10,
  )

  return (
    <div className='p-4 space-y-6'>
      {last10OnlineInvoices.length === 0 ? (
        <div className='text-center text-gray-500 text-lg'>No invoices available.</div>
      ) : (
        last10OnlineInvoices.map((invoice) => (
          <DisplayInvoice invoice={invoice} key={'inv' + invoice.invoiceId} />
        ))
      )}
    </div>
  )


  function DisplayInvoice({ invoice }: { invoice: Order }) {
    const contentRef = useRef<HTMLDivElement>(null)
    const reactToPrintFn = useReactToPrint({ contentRef })

    const products = productsByOrderId[invoice.orderId ?? ''] || []

    const shareInvoice = async () => {
      if (!contentRef.current) return
      try {
        const dataUrl = await toPng(contentRef.current)
        const link = document.createElement('a')
        link.href = dataUrl
        link.download = `${invoice.invoiceId}.png`
        link.click()

        // Share via WhatsApp
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          'Check out this invoice: ',
        )}&media=${dataUrl}`
        window.open(whatsappUrl, '_blank')
      } catch (error) {
        console.error('Failed to generate image:', error)
      }
    }

    return (
      <div>
        <div
          key={invoice.invoiceId}
          className='border border-gray-200 rounded-lg p-6 bg-white shadow-md'
          ref={contentRef}
        >
          {/* Invoice Header */}
          <div className='border-b border-gray-200 pb-6 mb-6'>
            <div className='flex justify-between items-center'>
              <div>
                <h1 className='text-xl font-bold text-gray-800'>Royal Bakes</h1>
                <p className='text-sm text-gray-600'>
                  Kirigaoka 1-20-38, Midori Ku, Yokohama shi, Kanagawa 226-00116
                  <br />
                  Phone: 080 3500 9515
                </p>
              </div>
            </div>
            <div className='mt-4 text-sm text-gray-600'>
              Invoice Date:{' '}
              {invoice.invoiceDate ? format(new Date(Number(invoice.invoiceDate)), 'PPp') : 'N/A'}
            </div>
          </div>

          {/* Invoice Details */}
          <div className='mb-6'>
            <div className='flex justify-between'>
              <div>
                <strong className='block text-gray-700'>Invoice #:</strong>
                <span className='text-gray-800'>{invoice.invoiceId}</span>
                <strong className='block text-gray-700 mt-2'>Customer Name:</strong>
                <span className='text-gray-800'>{invoice.deliveryName || 'N/A'}</span>

              </div>
              <div className='mt-4'>
                <strong className='block text-gray-700'>Payment Method:</strong>
                <span className='text-gray-800'>{invoice.paidMethod}</span>
                <strong className='block text-gray-700 mt-2'>Phone:</strong>
                <span className='text-gray-800'>{invoice.deliveryPhone || 'N/A'}</span>
              </div>
            </div>
          </div>

          {/* Products Table */}
          {products.length > 0 && (
            <div className='mt-6'>
              <h3 className='text-md font-semibold text-gray-800 mb-4'>Products</h3>
              <table className='w-full table-auto border-collapse border border-gray-200'>
                <thead>
                  <tr className='bg-gray-50 text-gray-700'>
                    <th className='border border-gray-200 p-2 text-left'>Product Name</th>
                    <th className='border border-gray-200 p-2 text-right'>Price</th>
                    <th className='border border-gray-200 p-2 text-right'>Quantity</th>
                    <th className='border border-gray-200 p-2 text-right'>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product, index) => (
                    <tr key={invoice.invoiceId + product.productId + index} className='text-gray-700'>
                      <td className='border border-gray-200 p-2'>{product.nameEn}</td>
                      <td className='border border-gray-200 p-2 text-right'>
                        {new Intl.NumberFormat('ja-JP', {
                          style: 'currency',
                          currency: 'JPY',
                        }).format(product.price)}
                      </td>
                      <td className='border border-gray-200 p-2 text-right'>
                        {product.quantity} x {product.unit}
                      </td>
                      <td className='border border-gray-200 p-2 text-right'>
                        {new Intl.NumberFormat('ja-JP', {
                          style: 'currency',
                          currency: 'JPY',
                        }).format(product.price * product.quantity)}
                      </td>
                    </tr>
                  ))}

                  {/* Total Amount */}
                  <tr className='font-bold'>
                    <td colSpan={2} className='border border-gray-200 p-2 text-right'>
                      Total Amount
                    </td>
                    <td colSpan={2} className='border border-gray-200 p-2 text-right font-extrabold'>
                      {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(
                        invoice.totalAmount,
                      )}
                    </td>
                  </tr>

                  {/* Taxes */}
                  {invoice.taxes && Object.keys(invoice.taxes).length > 0 ? (
                    Object.values(invoice.taxes).map((tax, index) => (
                      <tr key={`tax-${index}`} className='text-gray-700'>
                        <td
                          colSpan={2}
                          className='border border-gray-200 p-2 text-right font-semibold'
                        >
                          Tax ({tax.rate}%)
                        </td>
                        <td colSpan={2} className='border border-gray-200 p-2 text-right'>
                          {new Intl.NumberFormat('ja-JP', {
                            style: 'currency',
                            currency: 'JPY',
                          }).format(tax.amount)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={4}
                        className='border border-gray-200 p-2 text-center text-gray-500'
                      >
                        No taxes available
                      </td>
                    </tr>
                  )}

                  {/* Total Tax */}
                  <tr className='font-normal'>
                    <td colSpan={2} className='border border-gray-200 p-2 text-right'>
                      Total Tax
                    </td>
                    <td colSpan={2} className='border border-gray-200 p-2 text-right'>
                      {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(
                        invoice.taxAmount,
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Share and Print Buttons - Outside contentRef */}
        <div className='mt-6 print:hidden'>
          <button
            className='bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded mr-2'
            onClick={() => reactToPrintFn()}
          >
            Print
          </button>
          <button
            className='bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded'
            onClick={shareInvoice}
          >
            Share via WhatsApp
          </button>
        </div>
      </div>
    )
  }

}
