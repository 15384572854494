import { Page } from '../../base/Page'
import { InputText } from '../../base/InputText'
import { useForm } from 'react-hook-form'
import { Styles } from '../../base/Styles'
import * as Yup from 'yup'
import { useYupValidationResolver } from '../../base/validateResolver'
import { Product, ProductPricing } from '../../model/Product'
import { DataSnapshot, getDatabase, ref, serverTimestamp, set } from 'firebase/database'
import { firebaseApp } from '../../firebase'
import { useEffect, useState } from 'react'
import { Button, Card, Checkbox, Label, Modal, Select } from 'flowbite-react'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { DB } from '../../DB'
import ReactQuill from 'react-quill'
import { useCategories, useProduct } from '../../base/useDbHooks'
import { useProductPricing } from '../../base/useDbProductPricingHooks'
import { ProductImage } from './ProductImages'

const formSchema = Yup.object().shape({
  category: Yup.string().required('required'),
  productNameEn: Yup.string().required('required'),
  productNameJp: Yup.string().required('required'),
  productDescriptionEn: Yup.string().required('required'),
  productDescriptionJp: Yup.string().required('required'),
  taxRate: Yup.number().required('required'),
  isDirectOrder: Yup.boolean().required('required'),
})

const formSchemaPricing = Yup.object().shape({
  unit: Yup.string().required('required'),
  price: Yup.string().required('required'),
  stock: Yup.string().required('required'),
  displayOrder: Yup.number().required('required'),
})

const database = getDatabase(firebaseApp)

export default function ProductAddEdit() {
  const { categories } = useCategories()
  const { id } = useParams()
  const navigate = useNavigate()
  const { product } = useProduct(id)

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<Product>({ resolver: useYupValidationResolver(formSchema) })

  useEffect(() => {
    if (product) {
      reset(product)
    }
  }, [product, id])

  const onSubmit = (data: Product) => {
    const productId = data.productId ?? Math.random().toString(36).substring(2, 15)
    data.productId = productId
    data.createdDate = data.createdDate ?? serverTimestamp()
    data.updatedDate = serverTimestamp()

    console.log('product data', data)

    set(ref(database, DB.products + '/' + productId), data)
    toast.success('Product Saved')
    navigate('/products')
  }

  return (
    <Page title='Add Product'>
      <div className='flex flex-col items-center gap-6 px-1 sm:px-1 lg:px-8'>
        <Card className='w-full lg:w-3/5 md:w-4/5 sm:w-full '>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-6 '>
              <div className='flex flex-wrap gap-4 '>
                <div>
                  <div className='w-full '>
                    <Label htmlFor='category' value='Select your Category' />
                    <Select id='category' {...register('category')} required className='w-full'>
                      <option selected>Select</option>
                      {categories &&
                        categories.map((c) => (
                          <option
                            key={c.category}
                            value={c.category}
                            selected={getValues().category === c.category}
                          >
                            {c.nameEn}
                          </option>
                        ))}
                    </Select>
                  </div>
                  <div className='w-full '>
                    <InputText
                      {...register('productNameEn')}
                      name={'productNameEn'}
                      label='Product Name EN'
                      error={errors.productNameEn?.message}
                    />
                  </div>
                  <div className='w-full'>
                    <InputText
                      {...register('productNameJp')}
                      name={'productNameJp'}
                      label='Product Name JP'
                      error={errors.productNameJp?.message}
                    />
                  </div>
                </div>
                <div>
                  <ProductImage />
                </div>
              </div>

              <div className='flex flex-col md:flex-row gap-6'>
                <div className='w-full'>
                  <Label
                    className={errors?.productDescriptionEn ? 'text-red-700' : 'text-gray-900'}
                  >
                    Description English
                  </Label>
                  <ReactQuill
                    theme='snow'
                    value={getValues('productDescriptionEn')}
                    onChange={(v) => setValue('productDescriptionEn', v)}
                  />
                </div>
                <div className='w-full'>
                  <Label
                    className={errors?.productDescriptionJp ? 'text-red-700' : 'text-gray-900'}
                  >
                    Description Japanese
                  </Label>
                  <ReactQuill
                    theme='snow'
                    value={getValues('productDescriptionJp')}
                    onChange={(v) => setValue('productDescriptionJp', v)}
                  />
                </div>
              </div>

              <div className='flex flex-wrap items-center gap-4'>
                <div className='flex items-center gap-2'>
                  <InputText
                    {...register('taxRate')}
                    name={'taxRate'}
                    label='Tax Rate'
                    error={errors.taxRate?.message}
                  />
                  <span>%</span>
                </div>
                <div className='flex items-center gap-2'>
                  <Checkbox id='isDirectOrder' {...register('isDirectOrder')} />
                  <Label htmlFor='isDirectOrder'>Is Direct Order</Label>
                </div>
              </div>

              <div className='flex justify-end'>
                <button type='submit' className={Styles.button.default}>
                  Save
                </button>
              </div>
            </div>
          </form>
        </Card>

        <Pricings />
      </div>
    </Page>
  )

  function Pricings() {
    const { id } = useParams()
    const { productPricingSnapshots, addPricing, removePricing, updatePricing } =
      useProductPricing(id)

    if (!id || id === 'add') return null
    return (
      <Card className='w-full lg:w-3/5 md:w-4/5 sm:w-full'>
        <div className='bg-gray-200 rounded p-4'>
          <div className='text-lg font-bold text-center'>Pricing</div>
          <PricingEdit addPricing={addPricing} />
          {productPricingSnapshots.map((field) => (
            <PricingEdit
              key={field.key}
              productPricingSnapshot={field}
              addPricing={addPricing}
              removePricing={removePricing}
              updatePricing={updatePricing}
            />
          ))}
        </div>
      </Card>
    )
  }

  function PricingEdit({
    productPricingSnapshot,
    addPricing,
    removePricing,
    updatePricing,
  }: {
    productPricingSnapshot?: DataSnapshot
    addPricing: (productPricing: ProductPricing) => void
    removePricing?: (productPricingSnapshot: DataSnapshot) => void
    updatePricing?: (productPricingSnapshot: DataSnapshot, productPricing: ProductPricing) => void
  }) {
    const [pricing, setPricing] = useState<ProductPricing>({} as ProductPricing)
    const { id: productId } = useParams()

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<ProductPricing>({ resolver: useYupValidationResolver(formSchemaPricing) })

    useEffect(() => {
      const pricing = (productPricingSnapshot?.val() as ProductPricing) ?? {}
      setPricing(pricing)
      reset(pricing)
    }, [productPricingSnapshot, reset])

    const onSubmit = (data: ProductPricing) => {
      if (data.pricingId && updatePricing && productPricingSnapshot) {
        data.productId = productId
        updatePricing(productPricingSnapshot, data)
        toast.success('Price Saved')
      } else {
        data.productId = productId
        addPricing(data)
        reset({})
        toast.success('Price Added')
      }
    }

    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='w-full grid grid-cols-1 md:grid-cols-7 gap-2'
      >
        <InputText
          key={pricing.pricingId + 'unit'}
          {...register('unit')}
          name={'unit'}
          type='text'
          placeholder='Unit'
          error={errors.unit?.message}
        />
        <InputText
          key={pricing.pricingId + 'price'}
          {...register('price')}
          name={'price'}
          type='number'
          placeholder='Price'
          error={errors.price?.message}
        />
        <InputText
          key={pricing.pricingId + 'priceDiscount'}
          {...register('priceDiscount')}
          name={'priceDiscount'}
          type='number'
          placeholder='Price Discount'
          error={errors.priceDiscount?.message}
        />
        <InputText
          key={pricing.pricingId + 'stock'}
          {...register('stock')}
          name={'stock'}
          type='number'
          placeholder='Stock'
          error={errors.stock?.message}
        />
        <InputText
          key={pricing.pricingId + 'expireDate'}
          {...register('expireDate')}
          name={'expireDate'}
          type='date'
          placeholder='Expire Date'
          error={errors.expireDate?.message}
        />
        <InputText
          key={pricing.pricingId + 'displayOrder'}
          {...register('displayOrder')}
          name={'displayOrder'}
          type='number'
          placeholder='Display Order'
          error={errors.displayOrder?.message}
        />
        <div className='flex gap-2'>
          <button type='submit' className={Styles.button.default}>
            {!pricing?.pricingId && 'Add'}
            {pricing?.pricingId && 'Edit'}
          </button>
          <RemoveButton />
        </div>
      </form>
    )

    function RemoveButton() {
      const [showRemoveModal, setShowRemoveModal] = useState(false)

      return (
        <>
          <RemoveConfirmation
            show={showRemoveModal}
            onClick={() => {
              if (removePricing && productPricingSnapshot) {
                removePricing(productPricingSnapshot)
              }
              setShowRemoveModal(false)
            }}
            onClose={() => setShowRemoveModal(false)}
          />
          {pricing?.pricingId && (
            <button
              type='button'
              className={Styles.button.link}
              onClick={() => {
                setShowRemoveModal(true)
              }}
            >
              X
            </button>
          )}
        </>
      )
    }
  }

  function RemoveConfirmation({
    show,
    onClick,
    onClose,
  }: {
    show: boolean
    onClose?: () => void
    onClick?: () => void
  }) {
    return (
      <Modal show={show} size='md' popup={true} onClose={() => onClose && onClose()}>
        <Modal.Header />
        <Modal.Body>
          <div className='text-center'>
            <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
              Are you sure you want to delete this pricing?
            </h3>
            <div className='flex justify-center gap-4'>
              <Button
                color='failure'
                onClick={() => {
                  onClick && onClick()
                }}
              >
                Yes, Im sure
              </Button>
              <Button
                color='gray'
                onClick={() => {
                  onClose && onClose()
                }}
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
