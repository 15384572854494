import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { Page } from '../../base/Page'
import { Styles } from '../../base/Styles'
import { useOrders, useOrderItems } from '../../base/useDbOrders'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { Order } from '../../model/Order'

export default function Orders() {
  const { orders } = useOrders();
  const navigate = useNavigate();

  return (
    <Page title="Orders">
      <div className="flex justify-end mb-4">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200"
          onClick={() => navigate('/products/add')}
        >
          <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
          </svg>
          Add New Order
        </button>
      </div>
      <div className="flex flex-col gap-2">
        {!orders || orders.length === 0 ? (
          <div className="col-span-full text-center py-10 bg-gray-100 rounded-lg">
            <svg className="w-12 h-12 mx-auto text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 13h6m-3-3v6" />
            </svg>
            <p className="mt-2 text-gray-600">No orders found</p>
          </div>
        ) : (
          orders.map((order) => (
            <OrderCard key={order.orderId} order={order} />
          ))
        )}
      </div>
    </Page>
  );
}

function OrderCard({ order }: { order: Order }) {
  const navigate = useNavigate();
  const { orderProducts, loading } = useOrderItems(order.orderId || '');

  const date = order.orderDate ? format(new Date(Number(order.orderDate)), 'PPp') : '';

  // Calculate ready and total items
  const readyCount = orderProducts?.filter(p => p.readyForDelivery).length || 0;
  const totalCount = orderProducts?.length || 0;
  const allReady = totalCount > 0 && readyCount === totalCount;

  // Determine card background color based on readiness
  const cardClass = allReady
    ? 'p-4 bg-green-200 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200'
    : 'p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200';

  if (loading) return <div className="p-4 bg-white rounded-lg">Loading...</div>;

  return (
    <div className={cardClass}>
      <div className="flex justify-between items-center mb-2">
        <span className="text-sm text-gray-500">#{order.orderId}</span>
        <span className="text-sm font-semibold text-gray-700">{date}</span>
      </div>
      <div className="flex flex-col gap-1 mb-3">
        <span className="font-semibold text-lg">{order.deliveryName}</span>
        <span className="text-gray-600">{order.deliveryAddress}</span>
        <span className="text-gray-600">{order.deliveryPhone}</span>
      </div>
      <div className="flex justify-between items-center border-t pt-2">
        <div className="flex flex-col">
          <span className="text-sm text-gray-700">{order.orderStatus}</span>
          <span className="text-sm text-gray-600 font-bold text-lg">
            Ready: {readyCount}/{totalCount}
          </span>
        </div>
        <div className="flex items-center gap-1">
          <span className="text-xs text-gray-500">¥</span>
          <span className="text-lg font-bold">
            {new Intl.NumberFormat('ja-JP', { maximumFractionDigits: 0 }).format(order.totalAmount)}
          </span>
        </div>
        <button
          className="text-blue-600 underline hover:text-blue-800 transition-colors"
          onClick={() => navigate(`/orders/${order.orderId}`)}
        >
          Edit
        </button>
      </div>
    </div>
  );
}